import React from "react";
import ImageLinks from "../../services/ImageLinks";
import { makeStyles } from "@mui/styles";
import Text from "../common/Text";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import theme from "../../services/theme";
import web_bg from "../../assets/img/footer_bg_web.png";
import mobile_bg from "../../assets/img/footer_bg_mobile.png";

const useStyles = makeStyles(() => ({
  container_wrap: {
    padding: "60px 100px 30px 100px",
    backgroundImage: `url(${web_bg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    "@media (max-width: 600px)": {
      padding: "60px 30px 30px 30px",
      backgroundImage: `url(${mobile_bg})`,
    },
  },
  container: {
    display: "flex",
    justifyContent: "space-around",
    "@media (max-width: 600px)": {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      gap: 30,
    },
  },
  icons_flex: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    gap: 5,
    "@media (max-width: 600px)": {
      maxWidth: 230,
      margin: "auto",
      justifyContent: "center",
      gap: 15,
    },
  },
  icon_wrap: {
    border: "2px solid white",
    borderRadius: "50%",
    cursor: "pointer",
    height: 50,
    width: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    color: theme.colors.primary,
    height: 18,
    width: 18,
  },
}));

function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.container_wrap}>
      <div className={classes.container}>
        <div>
          <img
            src={ImageLinks.pidge_logo}
            height={60}
            width={"auto"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open("https://pidge.in/", "_blank");
            }}
          />
          <h2>Logistics Technology for All</h2>
        </div>

        <div>
          <Text semi style={{ fontSize: 12 }}>
            INFORMATION
          </Text>
          <Text
            semi
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open("https://pidge.in/privacy-policy/", "_blank");
            }}
          >
            Privacy Policy
          </Text>
          <Text
            semi
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open("https://pidge.in/terms-of-use/", "_blank");
            }}
          >
            Terms of Use
          </Text>
        </div>

        <div>
          <Text semi style={{ fontSize: 12 }}>
            FOLLOW US
          </Text>
          <div className={classes.icons_flex}>
            <div
              className={classes.icon_wrap}
              onClick={() => {
                window.open(
                  "https://api.whatsapp.com/send/?phone=%2B919319482670&text&type=phone_number&app_absent=0",
                  "_blank"
                );
              }}
            >
              <WhatsAppIcon className={classes.icon} />
            </div>
            <div
              className={classes.icon_wrap}
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/company/pidge-mobility/mycompany/",
                  "_blank"
                );
              }}
            >
              <LinkedInIcon className={classes.icon} />
            </div>
            <div
              className={classes.icon_wrap}
              onClick={() => {
                window.open(
                  "https://www.instagram.com/pidge_mobility/",
                  "_blank"
                );
              }}
            >
              <InstagramIcon className={classes.icon} />
            </div>
            <div
              className={classes.icon_wrap}
              onClick={() => {
                window.open("https://www.youtube.com/@pidge679", "_blank");
              }}
            >
              <YouTubeIcon className={classes.icon} />
            </div>
            <div
              className={classes.icon_wrap}
              onClick={() => {
                window.open(
                  "https://www.facebook.com/PidgeMobility/",
                  "_blank"
                );
              }}
            >
              <FacebookIcon className={classes.icon} />
            </div>
            <div
              className={classes.icon_wrap}
              onClick={() => {
                window.open("https://twitter.com/Pidge_mobility", "_blank");
              }}
            >
              <TwitterIcon className={classes.icon} />
            </div>
          </div>
        </div>

        <div className="d-flex">
          <img src={ImageLinks.make_in_india} />
          <img src={ImageLinks.digital_india} />
        </div>
      </div>
      <div>
        <Text semi style={{ fontSize: 12, textAlign: "center" }}>
          © 2023 Pidge | All Rights Reserve
        </Text>
      </div>
    </div>
  );
}

export default Footer;
